import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import './styles.scss';

const SiteMap = () => {
	return (
		<Layout>
			<Seo title="Orphan | EMFLAZA® (deflazacort)" description=" "/>
			<div className='relative min-h-screen page--orphan'>
				<Hero
					heroImage='hero-orphan.png'
					title='Information from industry-sponsored content'
					addedClass='blue-bg mt-5'
					useBreadcrumbs={false}
				/>

				<div className='px-8 text-sm sm:max-w-4xl lg:px-0 lg:pl-20 text-emflaza-blue-500' >
					<h2 className='text-4xl font-bold mb-3'>
						How Accumulating Real-World Evidence is Enhancing
						Our Understanding of the Management of Duchenne Muscular Dystrophy
					</h2>

					<p className='italic mb-3'>
						This article has been sponsored and written
						by PTC Therapeutics in collaboration with expert contributors.
					</p>

					<p className='mb-3'>
						Duchenne muscular dystrophy (DMD) is a rare and fatal genetic disorder that
						causes progressive muscle damage and degeneration.<sup>1,2</sup> It is
						the most common and severe form of muscular dystrophy among children,
						affecting ~1 in every 3,600–6,000 live male births.<sup>3,4</sup> DMD is caused
						by a mutation in the dystrophin gene that results
						in absent or insufficient functional dystrophin - an essential protein
						in the muscle cell membrane.<sup>1,5</sup> Children with DMD experience a progressive
						decline in functional ability, including loss of ambulation,
						and respiratory and cardiac complications, which lead to premature death.<sup>1,2</sup>
					</p>

					<p className='mb-10'>
						Early intervention can improve patient outcomes and it is therefore crucial
						to establish a treatment plan that helps delay disease progression.<sup>1,2</sup>
					</p>

					<h3 className='font-bold mb-3'>Foundational Role of Corticosteroids in DMD Management</h3>
					<p className='mb-3'>
						Corticosteroid therapy is standard of care for patients with DMD,
						with more evidence regarding their use accumulating each year.
						Along with other improvements in Duchenne management,
						corticosteroid therapy has positively impacted the natural
						history of the disease and its long-term use has been shown to delay disease milestones,
						including delayed loss in ambulation, slowing decline in pulmonary function,
						delaying the onset of cardiomyopathy, reducing the need for scoliosis surgery.<sup>1</sup>
					</p>

					<p className='mb-3'>
						Corticosteroids are thought to inhibit many of the initial
						events in an inflammatory response. A dysregulated immune
						response is central to the pathogenesis of DMD and
						can exacerbate membrane damage, chronic muscle damage and inflammation.
						Corticosteroids block the accumulation of dystrophin reactive
						CD8-T cells in DMD emphasizing the benefit as
						anti-inflammatory and immunosuppressive therapies.<sup>6</sup>
					</p>

					<p className='mb-3'>
						The DMD standard of care guidelines highlight recent studies
						that confirm the benefits of starting corticosteroids in
						younger children with DMD and recommend corticosteroid
						initiation before substantial physical decline.<sup>1</sup>
						An intervention of corticosteroids at a later stage is still
						beneficial and it is recommended that individuals should
						continue taking corticosteroids after loss of ambulation.<sup>1,4</sup>
					</p>

					<p className='mb-3'>
						Growing experience and emerging evidence on corticosteroid
						treatment further supports and enhances the understanding of the clinical benefits.
						In 2017, the FDA approved <br className='block xl:hidden'/> EMFLAZA<sup>®</sup> (deflazacort), the first and only corticosteroid indicated
						for the treatment of DMD in patients 5 years of age, with indication of 2 years and older in 2019.
						The benefits of EMFLAZA are well-established and supported by a consistent body of
						clinical and real-world evidence vs prednisone, an older generation corticosteroid,
						including improved muscle strength, prolonged ambulation, and significantly delayed
						loss of motor functions.<sup>7,12</sup>
					</p>

					<p className='mb-10'>
						Demonstrating in a phase III, double-blind,
						randomized, placebo-controlled, multicenter study evaluating muscle strength among 196
						boys aged 5-15 years with DMD during a 52-week period<sup className='text-xs'>†</sup>,
						boys taking EMFLAZA gained less weight compared with those taking prednisone,
						were less likely to experience psychiatric side effects such as abnormal behavior,
						aggression, irritability, and mood swings compared with those taking prednisone,
						and were less likely to discontinue treatment due to side effects.<sup>7</sup> With the use of prednisone,
						doctors frequently modify dosing due to side effects that are commonly experienced
						such as weight gain and behavioral changes.
					</p>

					<h3 className='font-bold mb-3'>Importance of Real-World Data to Understanding Long-Term Patient Outcomes</h3>
					<p className='mb-10'>
						Real-world studies are vital to enhancing our understanding
						of the long-term safety and effectiveness of a treatment,
						as the real-world data are collected outside of a controlled environment.
						By studying how a treatment is used by a diverse patient population
						in everyday environments, we gain insights about the people
						and settings underrepresented in controlled clinical trials and risks of treatments
						when dosed under real-world conditions.
					</p>

					<h3 className='font-bold mb-3'>Latest Real-World Evidence Behind Emflaza in the Management of DMD</h3>
					<p className='mb-3'>
						EMFLAZA is a different kind of corticosteroid. It is a prodrug whose active metabolite,
						21-desDFZ, acts through the glucocorticoid receptor to exert anti-inflammatory
						and immunosuppressive effects. EMFLAZA has a distinct molecular structure,
						resulting in unique pharmacologic characteristics, such as lower liposolubility
						and reduced blood-brain barrier penetration.<sup>8,9</sup>
					</p>

					<p className="mb-3">
						In recent years, the number of studies demonstrating
						the real-world clinical benefit of deflazacort has grown,
						including a real-world study, a retrospective chart analysis,
						evaluating the effects of switching from prednisone to deflazacort
						in patients with DMD in the US. Physicians were asked to report
						the reasons for switching from prednisone to deflazacort
						for each patient as well as their impressions of the patients' progress
						as measured by Clinical Global Impression (CGI).
						In total, 55 treating physicians responded to the study survey with the
						majority giving “a desire to slow disease progression” and “reducing tolerability issues”
						as the main reason for switching their DMD patients from prednisone to EMFLAZA.
						In total, 62 DMD boys with a mean age of 6.2 years old were switched.
						During an average of 6 months' follow-up after switching,
						the CGI outcomes were consistent, demonstrating that treatment
						with Emflaza addressed the physicians’ primary reason for switching.<sup>10</sup>
					</p>

					<p className='mb-3'>
						Susan Apkon, MD, Chief of Pediatric Rehabilitation Medicine at Children’s
						Hospital Colorado and  Vice Chair of Physical Medicine and Rehabilitation
						at the University of Colorado School of Medicine, emphasized the significance
						of this real-world study and highlighted the importance of switching
						sooner for improved outcomes and tolerability. “The sustained clinical
						benefit and favorable profile evidenced by deflazacort in a real-world
						setting support the potential of deflazacort to alter the natural history
						of DMD and reveals the urgency of switching from prednisone sooner.
						When establishing a treatment plan for a progressive disease like DMD,
						it is important to prioritize preservation of function while ensuring
						the patient remains comfortable. From what we’ve seen and continue to see,
						deflazacort is superior in achieving these considerations, which is what sets
						it apart from prednisone/prednisolone.”
					</p>

					<p className='mb-3'>
						Additional data from another real-world retrospective study of long-term treatment
						with deflazacort versus prednisone in 435 boys with DMD
						from Cincinnati Children’s Hospital Medical Center further supports
						the importance of deflazacort and its clinical benefit.
						Patients receiving deflazacort had lower risk of losing ambulation,
						lost ambulation at older ages than those receiving prednisone and had lower risk of scoliosis.
						In addition, treatment with deflazacort was associated
						with greater preservation of ambulatory and pulmonary function, shorter stature,
						lower body weight and a greater percentage of lean body mass.<sup>11</sup>
					</p>

					<p className='mb-3'>
						Together, these studies add to the growing evidence associating real-world
						dosing of deflazacort with improved outcomes for patients with DMD.
					</p>

					<h3 className='font-bold mb-3'>
						<sup className='text-xl top-0'>†</sup>Griggs 2016 Clinical Study Information
					</h3>
					<h3 className='font-bold mb-3'>
						Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy
					</h3>

					<p className='mb-3'>
						A phase III, double-blind, randomized, placebo-controlled,
						multicenter study evaluated muscle strength among 196 boys aged 5–15 years
						with DMD during a 52-week period.
					</p>

					<p className='mb-3'>
						In phase 1, participants were randomly assigned to receive treatment with deflazacort
						0.9 mg/kg/d (n=51), deflazacort <br className="hidden xl:block" /> 1.2 mg/kg/d (n=49),
						prednisone 0.75 mg/kg/d (n=46), or placebo (n=50) for 12 weeks.
						In phase 2, placebo participants were randomly assigned to 1 of the 3 active treatment groups.
						Participants originally assigned to an active treatment continued that
						treatment for an additional 40 weeks. The primary efficacy endpoint was average change
						in muscle strength from baseline to week 12 compared with placebo.
					</p>

					<p className='mb-3'>
						Griggs RC, Miller JP, Greenberg CR, et al.
						Efficacy and safety of deflazacort vs prednisone and placebo
						for Duchenne muscular dystrophy. <span className='italic'>Neurology</span>. 2016;87(20):2123-2131
					</p>

					<p className='text-xxs mb-10 leading-4'>
						US-EMF-0311  08/21
					</p>

					<h3 className='italic mb-3'>References</h3>
					<ul>
						<li>Birnkrant DJ, et al. Lancet Neurol.2018;17:251–267</li>
						<li>van Ruiten HJ, et al. Arch Dis Child. 2014;99:1074–1077</li>
						<li>
							National Institutes of Health. What are the types of muscular dystrophy?
							Available at: https://www.nichd.nih.gov/health/topics/musculardys/conditioninfo/types
							[last accessed April 2021].
						</li>
						<li>Bushby K, et al. Lancet Neurol. 2010;9:77‒93</li>
						<li>Goemans N, et al. Eur Neurol Rev. 2014;8:78–82</li>
						<li>Flanigan KM, et al. Hum Gene Ther.2013;24(9):797-806.</li>
						<li>
							Griggs RC, Miller JP, Greenberg CR, et al. Efficacy and safety of
							deflazacort vs prednisone and placebo for Duchenne muscular dystrophy.
							Neurology. 2016;87(20):2123-2131
						</li>
						<li>Emflaza. Package insert. PTC Therapeutics, Inc; 2019</li>
						<li>
							Parente L. Deflazacort: therapeutic index, relative potency and equivalent doses
							versus other corticosteroids. BMC Pharmacol Toxicol. 2017;18(1).
						</li>
						<li>
							Marden J, Steroid Switching in the Treatment of Dystrophinopathies in the US:
							a Nationwide Chart Review of Patient Characteristics and Clinical Outcomes.
							Poster presented at the 2021 MDA Virtual Clinical & Scientific Conference of
							the Muscular Dystrophy Association (MDA), March 15-18, 2021.
						</li>
						<li>
							Marden JR, Real-world outcomes of long-term prednisone and deflazacort
							use in patients with Duchenne muscular dystrophy: experience at a single,
							large care center. et al. J Comp Eff Res. 2020;9(3):177-189
						</li>
						<li>
							Bello L, Gordish-Dressman H, Morgenroth LP, et al; CINRG Investigators.
							Prednisone/prednisolone and deflazacort regimens in the CINRG Duchenne Natural History Study.
							Neurology. 2015;85(12):1048-1055
						</li>
					</ul>
				</div>
			</div>
		</Layout>
	);
};

export default SiteMap;
